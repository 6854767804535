import { Box } from '@mui/material';

import { BuildRepacksProvider } from '../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext';
import ViewRepacks from '../Dash/RepackPage/ViewRepacks/ViewRepacks';

const ViewRepackPage = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        ><BuildRepacksProvider>
            <ViewRepacks/>
        </BuildRepacksProvider>

    </Box>
  );
  
  
  export default ViewRepackPage;