import { Box } from '@mui/material';
import logo from '../../images/WTH_HORIZONTAL.png';
import styles from '../CustomerPageStyles/ProductPageHeader.module.css';

const ProductPageHeader = () => {
    return (
        <Box className={styles.headerContainer}>
            <Box>
                <img 
                    src={logo}
                    alt="WeTheHobby"
                    className={styles.logo}
                />
            </Box>
            <Box className={styles.addressBox}>
                <div>23 Arlington Street</div>
                <div>Rochester, NY 14607</div>
            </Box>
        </Box>
    );
};

export default ProductPageHeader;