import { Menu, MenuItem, Checkbox, ListItemText, IconButton } from "@mui/material";
import { useState } from "react";
import FilterListIcon from '@mui/icons-material/FilterList'; // Import this if you want an icon
import styles from '../CustomerPageStyles/ProductTypeFilter.module.css';

interface ProductTypeFilterProps {
  selectedCategory: string[];
  setSelectedCategory: (categories: string[]) => void;
  categories?: string[];
}

const ProductTypeFilter: React.FC<ProductTypeFilterProps> = ({ selectedCategory, setSelectedCategory, categories }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        className={styles.filterButton}
        color={open ? "primary" : "default"}
        onClick={handleClick}
      >
        <FilterListIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: styles.filterMenu
        }}
      >
         <MenuItem>
          <Checkbox 
            checked={categories?.length === selectedCategory.length}
            indeterminate={selectedCategory.length > 0 && selectedCategory.length < (categories?.length ?? 0)}
            onChange={() => {
              if (selectedCategory.length === categories?.length) {
                setSelectedCategory([]);
              } else {
                setSelectedCategory(categories || []);
              }
            }}
          />
          <ListItemText 
            primary="Select All"
            onClick={() => {
              if (selectedCategory.length > 0) {
                setSelectedCategory([]);
              } else {
                setSelectedCategory(categories || []);
              }
            }}
          />
        </MenuItem>
          {categories?.map((category) => (
            <MenuItem 
              key={category} 
              onClick={() => {
                const newSelected = selectedCategory.includes(category)
                  ? selectedCategory.filter(item => item !== category)
                  : [...selectedCategory, category];
                setSelectedCategory(newSelected);
              }}
            >
              <Checkbox 
                checked={selectedCategory.indexOf(category) > -1}
                onChange={() => {
                  const newSelected = selectedCategory.includes(category)
                    ? selectedCategory.filter(item => item !== category)
                    : [...selectedCategory, category];
                  setSelectedCategory(newSelected);
                }}
              />
              <ListItemText primary={category} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default ProductTypeFilter;