import { TextField } from "@mui/material";

interface ProductSearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const ProductSearchBar: React.FC<ProductSearchBarProps> = ({ searchTerm, setSearchTerm }) => (
  <TextField
    label="Search Products"
    variant="outlined"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    sx={{ flexGrow: 1, width: '90%' }}
  />
);

export default ProductSearchBar;