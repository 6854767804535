import React, { useState, useEffect } from 'react';
import { 
  GridColDef,
} from '@mui/x-data-grid-premium';
import { 
  Box, 
} from '@mui/material';
import api from "../../Modules/APIModules";
import { matchSorter } from "match-sorter";
import useDebounce from '../../Components/CommonUtility/useDebounce';
import ProductGrid from './ProductGrid';
import ProductLeagueChips from './ProductLeagueChips';
import ProductSportTabs from './SportsTabs';
import ProductTypeFilter from './ProductTypeFilter';
import ProductSearchBar from './ProductSearchBar';
import ProductPageHeader from './ProductPageHeader';
import '../CustomerPageStyles/wholesale.css';


interface Product {
  id: number;
  name: string;
  releaseDate: string;
  price: number;
  type: string;
  league: string; // Hidden attribute for filtering
  year: string;
}

const ProductPage: React.FC = () => {
  const [rows, setRows] = useState<Product[]>([]);
  const [initialRows, setInitialRows] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [sports, setSports] = useState<any>();
  const [categories, setCategories] = useState<string[]>();

  const [selectedMainChip, setSelectedMainChip] = useState<string | null>(null);
  const [subChips, setSubChips] = useState<any[]>([]);
  const [selectedSubChips, setSelectedSubChips] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

  const handleChipClick = (chip: any) => {
    if (selectedMainChip === chip) {
      setSelectedMainChip(null);
      setSubChips([]);
      setSelectedSubChips([]);
    } else {
      setSelectedMainChip(chip);
      const subs = sports?.find((sport:any) => sport.name === chip.name)?.leagues;
      const uniqueLeagues = Array.from(new Set(subs.map((league:any) => league.name))) as string[];
      uniqueLeagues.push("All");
      uniqueLeagues.sort((a: string, b: string) => {
        if (a === "All") return -1;
        if (b === "All") return 1;
        return a.localeCompare(b);
      });
      setSubChips(uniqueLeagues);
      setSelectedSubChips(["All"]); // Select "All" by default
    }
  };

  const handleSubChipClick = (subChip: string) => {
    if (subChip === "All") {
      // Toggle all chips
      setSelectedSubChips(selectedSubChips.includes("All") ? [] : [...subChips]);
    } else {
      if (selectedSubChips.includes("All")) {
        // If "All" was selected, clicking another chip should select only that chip
        setSelectedSubChips([subChip]);
      } else {
        // Toggle individual chip
        setSelectedSubChips(prev => 
          prev.includes(subChip) 
            ? prev.filter(chip => chip !== subChip)
            : [...prev, subChip]
        );
      }
    }
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 250);

  useEffect(() => {
    let filteredRows:Product[] = [];
    if(debouncedSearchTerm === undefined || debouncedSearchTerm === null) {
      filteredRows = initialRows;
    }
    else if(debouncedSearchTerm === ""){
      filteredRows = [...initialRows].sort((a: Product, b: Product) => {
        // If one has year and other doesn't, year-having item goes first
        if (a.year && !b.year) return -1;
        if (!a.year && b.year) return 1;
        
        // If both have years, sort by year (desc) then name
        if (a.year && b.year) {
          if (a.year !== b.year) {
            return b.year.localeCompare(a.year);
          }
          return a.name.localeCompare(b.name);
        }
        
        // If both are null years, sort by name
        return a.name.localeCompare(b.name);
      });
    }
    else{
      
      filteredRows = matchSorter(initialRows, debouncedSearchTerm, { keys: ['name'] });
    }


    if(selectedSubChips.length === 1 && selectedSubChips[0] === "All"){
      filteredRows = filteredRows.filter((row:Product) => subChips.includes(row.league));
    }
    else if(selectedSubChips.length > 0){
      filteredRows = filteredRows.filter((row:Product) => selectedSubChips.includes(row.league));
    }
    else if(selectedMainChip){
      filteredRows = [];
    }
    if(selectedCategory.length > 0){
      filteredRows = filteredRows.filter((row:Product) => selectedCategory.includes(row.type));
    }



    setRows(filteredRows);
  },[debouncedSearchTerm,selectedSubChips,selectedCategory])

  useEffect(() => {
    const fetchSports = async () => {
      const sportResponse = await fetch(api.getLink() + "/Product/sports");
      const sportData = await sportResponse.json();

      const leagueReponse = await fetch(api.getLink() + "/Product/leagues");
      const leagueData = await leagueReponse.json();

      const mainChipData = sportData.map((sport:any) => {
        return {
          name: sport.name,
          leagues: leagueData.filter((league:any) => league.sport === sport.name)
        }
      })

      setSports(mainChipData);
    };

    const fetchProducts = async () => {
      const productResponse = await fetch(api.getLink() + "/Product");
      const productData = await productResponse.json();
      setRows(productData);
      setInitialRows(productData);
      const types = Array.from(new Set(productData.map((product: Product) => product.type))).sort();
      setCategories(types as string[]);
      setSelectedCategory(types as string[]);
    }

    

    fetchSports();
    fetchProducts();
  }, []);

  useEffect(() => {
    if(loading && sports && rows) {
      setLoading(false);
    }
  },
  [sports,rows])

  const hasReleaseDates = rows.some(row => Boolean(row.releaseDate));

  const columns: GridColDef[] = [
    { 
      field: 'name', 
      headerName: 'Name', 
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => (
        <div className="name-cell">
          {params.value}
        </div>
      )
    },
    ...(hasReleaseDates ? [{ 
      field: 'releaseDate', 
      headerName: 'Release Date', 
      flex: 1,
      minWidth: 120,
    }] : []),
    { 
      field: 'price', 
      headerName: 'Price',
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      valueFormatter: (params: any) => 
        `$${params.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
      renderCell: (params: any) => (
        <Box sx={{
          textAlign: 'center' 
        }}>
          ${params.value.toLocaleString('en-US', { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          })}
        </Box>
      ),
    }
  ];

  return (

    <div className="homepage">
      
      <ProductPageHeader />
        <div className="homepage-body">
          <Box sx={{ height: '100vh', width: '100%', p: 1,}}>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              
              <ProductTypeFilter 
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categories={categories}
              />

              <ProductSearchBar 
                searchTerm={searchTerm} 
                setSearchTerm={setSearchTerm} 
              />
            </Box>
            
            <Box sx={{ mb: 2 }}>
              <ProductSportTabs 
                sports={sports}
                selectedMainChip={selectedMainChip}
                handleChipClick={handleChipClick}
              />

              {selectedMainChip && (
                <ProductLeagueChips 
                  subChips={subChips}
                  selectedSubChips={selectedSubChips}
                  handleSubChipClick={handleSubChipClick}
                />
              )}
            </Box>

            <ProductGrid 
              rows={rows}
              columns={columns}
              loading={!(rows && sports && categories)}
            />
          </Box>
        </div>
    </div>

    
  );

};

export default ProductPage;
