import { Chip, Box, Stack } from "@mui/material";

interface ProductSportTabsProps {
  sports: any[];
  selectedMainChip: any;
  handleChipClick: (sport: any) => void;
}
const ProductSportTabs: React.FC<ProductSportTabsProps> = ({ sports, selectedMainChip, handleChipClick }) => (
  <Box sx={{ overflowX: 'auto', width: '100%' }}>
      <Stack
        direction="row"
        spacing={.5}
        sx={{ minWidth: 'min-content' }}  
      >
        {sports?.map((sport) => (
          <Chip
            sx={{
              border: '1px solid #e0e0e0',
              borderRadius: '0px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            }}
            key={sport.name}
            label={sport.name}
            onClick={() => handleChipClick(sport)}
            color={selectedMainChip === sport ? "primary" : "default"}
          />
        ))}
      </Stack>
    </Box>
);

export default ProductSportTabs;