import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import CartViewToolbar from "./CartViewToolbar";
import "./Repack.css";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";

export default function RepackStorePreview() {
  const {
    setSelectedCards,
    selectedForRepack,
    removeFromRepackStoreRepackVersion,
  } = useBuildRepacks();

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "",
      renderCell: (params: GridRenderCellParams) => {
        const id = params.id;
        const row = { ...params.row };
        return (
          <GridActionsCellItem
            key={"RepackPreviewDelete-" + id}
            icon={<DeleteIcon />}
            label="Remove"
            className="textPrimary"
            onClick={() => {
              removeFromRepackStoreRepackVersion([row]);
            }}
            color="inherit"
          />
        );
      },
    },
    { field: "inventoryId", headerName: "Inv Id", editable: false },
    { field: "name", headerName: "Card", editable: false },
    { field: "comp", headerName: "Comp", editable: false },
    { field: "totalCost", headerName: "Total Cost", editable: false },
  ];

  const [selectionModel, setSelectionModel] = useState<any[]>([]);

  return (
    <CollapsiblePaper
      title="Repack Store Preview"
      openTitle="View Repack Store Preview"
      closeTitle="Hide Repack Store Preview"
    >
      <Box sx={{ width: "100%", height: "auto" }}>
        <DataGridPro
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slots={{
            toolbar: CartViewToolbar,
          }}
          slotProps={{
            toolbar: { displayType: "repackStorePreview" },
            row: {
              draggable: "true",
            },
          }}
          density="compact"
          rows={selectedForRepack}
          columns={columns}
          pagination={false}
          disableColumnReorder={true}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = selectedForRepack.filter((row: any) =>
              selectedIDs.has(row.id)
            );
            setSelectedCards(selectedRows);
            setSelectionModel(ids);
          }}
          rowSelectionModel={selectionModel}
          autoHeight
        />
      </Box>
    </CollapsiblePaper>
  );
}