import React from "react";
import { Card, CardContent, Grid, Typography, Box, Button } from "@mui/material";
import {
  Warning as WarningIcon,
  CancelOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import "./RepackViewer.css";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";

interface RepackDetail {
  id: number;
  name: string;
  comp: number;
  totalCost: number;
}

interface GroupedData {
  count: number;
  totalComp: number;
  totalCost: number;
  details: RepackDetail[];
}

interface RepackData {
  id: number;
  serialNumber: string;
  type: string;
  status: string;
  series: number;
  profit: number;
  location: string;
  containsUnicorn: boolean;
  count: number;
  details: RepackDetail[];
}

interface InfoGridProps {
  data: RepackData[];
}

const InfoGrid: React.FC<InfoGridProps> = ({ data }) => {
  const renderIcon = (value: number) => {
    if (value > 20) {
      return <CheckCircleOutline color="success" />;
    } else if (value > 10) {
      return <WarningIcon color="warning" />;
    } else {
      return <CancelOutlined color="error" />;
    }
  };

  const groupedData = data.reduce<Record<string, GroupedData>>((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = {
        count: 0,
        totalComp: 0,
        totalCost: 0,
        details: [],
      };
    }

    acc[item.type].count += item.count;
    item.details.forEach((detail: RepackDetail) => {
      acc[item.type].totalComp += detail.comp;
      acc[item.type].totalCost += detail.totalCost;
      acc[item.type].details.push(detail);
    });

    return acc;
  }, {});

  const totalRepacks = data.reduce((acc, item) => acc + item.count, 0);

  const groupedByLocation = data.reduce<Record<string, GroupedData>>(
    (acc, item) => {
      if (!acc[item.location]) {
        acc[item.location] = {
          count: 0,
          totalComp: 0,
          totalCost: 0,
          details: [],
        };
      }

      acc[item.location].count += item.count;
      item.details.forEach((detail: RepackDetail) => {
        acc[item.location].totalComp += detail.comp;
        acc[item.location].totalCost += detail.totalCost;
        acc[item.location].details.push(detail);
      });

      return acc;
    },
    {}
  );

  const renderGridItem = (
    label: string,
    value: number,
    totalComp: number,
    totalCost: number
  ) => (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center">
            <Box className="icon-container" marginRight={2}>
              {renderIcon(value)}
            </Box>
            <Box className="grid-item">
              <Typography variant="h6">{label}</Typography>
              <Typography variant="subtitle1">{value}</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            {label !== "Total Repacks" && (
              <div>
                <Typography variant="body2">Total Comp: {totalComp}</Typography>
                <Typography variant="body2">Total Cost: {totalCost}</Typography>
              </div>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );

  const handleCopyInfo = () => {
    const copyText =
      `Total Repacks: ${totalRepacks}\n` +
      Object.entries(groupedData)
        .map(
          ([type, { count, totalComp, totalCost }]) =>
            `Remaining ${type}: ${count}, Total Comp: ${totalComp}, Total Cost: ${totalCost}`
        )
        .join("\n");

    const locationTotals = Object.entries(groupedByLocation)
      .map(
        ([location, { count, totalComp, totalCost }]) =>
          `\nLocation: ${location}\nRemaining: ${count}, Total Comp: ${totalComp}, Total Cost: ${totalCost}`
      )
      .join("\n");

    navigator.clipboard
      .writeText(copyText + locationTotals)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <CollapsiblePaper
      title="Repack Tickets"
      openTitle="View Repack Tickets Info"
      closeTitle="Hide Repack Tickets Info"
    >
      <Grid container spacing={3}>
        {/* Total Repacks */}
        {renderGridItem("Total Repacks", totalRepacks, 0, 0)}

        {/* Type Counts */}
        {Object.entries(groupedData).map(([type, { count, totalComp, totalCost }]) => (
          <React.Fragment key={type}>
            {renderGridItem(`Remaining ${type}`, count, totalComp, totalCost)}
          </React.Fragment>
        ))}
      </Grid>

      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleCopyInfo}>
          Copy All Info
        </Button>
      </Box>
    </CollapsiblePaper>
  );
};

export default InfoGrid;