import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridRowId } from "@mui/x-data-grid";
import RepackDetails from "./RepackDetails";

interface RepackDetails {
  id: number;
  name: string;
  comp: number;
  totalCost: number;
}

interface Repack {
  id: number;
  serialNumber: string;
  type: string;
  status: string;
  series: number;
  profit: number;
  location: string;
  containsUnicorn: boolean;
  details: RepackDetails[];
}

interface TabsProps {
  repacks: Repack[];
}

const RepackTabs: React.FC<TabsProps> = ({ repacks }) => {
  const [value, setValue] = useState("all");
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "serialNumber", headerName: "Series", flex: 1 },
    { field: "sport", headerName: "Sport", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "series", headerName: "Series", flex: 1 },
    { field: "profit", headerName: "Profit", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
  ];

  const getUniqueLocations = () => {
    const locationsSet = new Set<string>();
    repacks.forEach((item) => {
      locationsSet.add(item.location);
    });
    return Array.from(locationsSet);
  };

  const handleDetailPanelExpandedRowIdsChange = useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
      );
    },
    []
  );

  const detailPanelContent = useCallback((row: any) => {
    return <RepackDetails details={row.row.details} />;
  }, []);

  const uniqueLocations = getUniqueLocations();

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {["all", ...uniqueLocations].map((location) => (
              <Tab
                key={location}
                label={location === "all" ? "All" : location}
                value={location}
              />
            ))}
          </TabList>
        </Box>
        {["all", ...uniqueLocations].map((location) => (
          <TabPanel key={location} value={location}>
            <div style={{ height: "90%", width: "100%" }}>
              <DataGridPro
                rows={
                  location === "all"
                    ? repacks
                    : repacks.filter((item) => item.location === location)
                }
                columns={columns}
                onDetailPanelExpandedRowIdsChange={
                  handleDetailPanelExpandedRowIdsChange
                }
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                getDetailPanelContent={detailPanelContent}
                getDetailPanelHeight={() => "auto"}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0
                    ? "even-row"
                    : "odd-row"
                }
                sx={{
                  "& .MuiDataGrid-row.even-row": {
                    backgroundColor: "#f5f5f5",
                  },
                  "& .MuiDataGrid-row.odd-row": {
                    backgroundColor: "#ffffff",
                  },
                }}
                density="compact"
              />
            </div>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default RepackTabs;
