import { DataGridPremium , GridColDef} from "@mui/x-data-grid-premium";
import { Product } from "../ProductTypes";
import styles from '../CustomerPageStyles/ProductGrid.module.css';

interface ProductGridProps {
  rows: Product[];
  columns: GridColDef[];
  loading: boolean;
}

const ProductGrid: React.FC<ProductGridProps> = ({ rows, columns, loading }) => (
  <DataGridPremium
    rows={rows}
    columns={columns}
    loading={loading}
    hideFooterRowCount
    className={styles.dataGrid}
  />
);

export default ProductGrid;