const mockData = [
  {
    id: 0,
    serialNumber: "Series 16",
    type: "Prophecy",
    sport: "MLB",
    status: "Reserved",
    series: 1001,
    profit: 1500,
    location: "Whatnot",
    containsUnicorn: false,
    count: 3,
    details: [
      { id: 1, name: "Pantheon", comp: 500, totalCost: 1000 },
      { id: 2, name: "Hero", comp: 300, totalCost: 800 },
      { id: 3, name: "Prophecy", comp: 200, totalCost: 600 },
    ],
  },
  {
    id: 1,
    serialNumber: "Series 17",
    type: "Hero",
    sport: "NBA",
    status: "Packed",
    series: 2002,
    profit: 1200,
    location: "Whatnot Deuce",
    containsUnicorn: true,
    count: 3,
    details: [
      { id: 4, name: "Card X", comp: 600, totalCost: 1100 },
      { id: 5, name: "Card Y", comp: 400, totalCost: 900 },
      { id: 6, name: "Card Z", comp: 300, totalCost: 700 },
    ],
  },
  {
    id: 2,
    serialNumber: "Series 18",
    type: "Pantheon",
    sport: "NFL",
    status: "Allocated to WTH2",
    series: 3003,
    profit: 900,
    location: "NFL Fanatics",
    containsUnicorn: false,
    count: 3,
    details: [
      { id: 7, name: "Pantheon", comp: 500, totalCost: 1000 },
      { id: 8, name: "Hero", comp: 300, totalCost: 800 },
      { id: 9, name: "Prophecy", comp: 200, totalCost: 600 },
    ],
  },
  {
    id: 3,
    serialNumber: "Series 19",
    type: "Hero",
    sport: "NBA",
    status: "Allocated to WTH1",
    series: 4004,
    profit: 3000,
    location: "MLB Fanatics",
    containsUnicorn: true,
    count: 3,
    details: [
      { id: 10, name: "Pantheon", comp: 500, totalCost: 1000 },
      { id: 11, name: "Hero", comp: 300, totalCost: 800 },
      { id: 12, name: "Prophecy", comp: 200, totalCost: 600 },
    ],
  },
  {
    id: 4,
    serialNumber: "Series 20",
    type: "Hero",
    sport: "NBA",
    status: "Packed",
    series: 5005,
    profit: 1200,
    location: "NBA Fanatics",
    containsUnicorn: false,
    count: 3,
    details: [
      { id: 13, name: "Card X", comp: 700, totalCost: 1200 },
      { id: 14, name: "Card Y", comp: 500, totalCost: 1000 },
      { id: 15, name: "Card Z", comp: 400, totalCost: 800 },
    ],
  },
  {
    id: 5,
    serialNumber: "Series 21",
    type: "Hero",
    sport: "NBA",
    status: "Packed",
    series: 5005,
    profit: 1300,
    location: "NBA Fanatics",
    containsUnicorn: true,
    count: 3,
    details: [
      { id: 16, name: "Card X", comp: 800, totalCost: 1300 },
      { id: 17, name: "Card Y", comp: 600, totalCost: 1100 },
      { id: 18, name: "Card Z", comp: 500, totalCost: 900 },
    ],
  },
  {
    id: 6,
    serialNumber: "Series 22",
    type: "Prophecy",
    sport: "MLB",
    status: "Reserved",
    series: 1001,
    profit: 1600,
    location: "MLB Fanatics",
    containsUnicorn: false,
    count: 3,
    details: [
      { id: 19, name: "Pantheon", comp: 600, totalCost: 1100 },
      { id: 20, name: "Hero", comp: 400, totalCost: 900 },
      { id: 21, name: "Prophecy", comp: 300, totalCost: 700 },
    ],
  },
  {
    id: 7,
    serialNumber: "Series 23",
    type: "Hero",
    sport: "NBA",
    status: "Allocated to WTH2",
    series: 4004,
    profit: 2800,
    location: "NFL Fanatics",
    containsUnicorn: true,
    count: 3,
    details: [
      { id: 22, name: "Card X", comp: 800, totalCost: 1300 },
      { id: 23, name: "Card Y", comp: 600, totalCost: 1100 },
      { id: 24, name: "Card Z", comp: 500, totalCost: 900 },
    ],
  },
];

export default mockData;
