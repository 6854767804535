import { Chip, Stack } from "@mui/material";

interface ProductLeagueChipsProps {
  subChips: string[];
  selectedSubChips: string[];
  handleSubChipClick: (subChip: string) => void;
}

const ProductLeagueChips: React.FC<ProductLeagueChipsProps> = ({ subChips, selectedSubChips, handleSubChipClick }) => (
  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
    {subChips.map((subChip) => (
      <Chip
        key={subChip}
        label={subChip}
        variant="outlined"
        onClick={() => handleSubChipClick(subChip)}
        color={selectedSubChips.includes(subChip) ? "primary" : "default"}
      />
    ))}
  </Stack>
);

export default ProductLeagueChips;