import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGridPro, GridRowId } from "@mui/x-data-grid-pro";
import CartViewToolbar from "./CartViewToolbar";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import "./Repack.css";
import { RepackTicket } from "../Types/RepackTicket";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";

export default function TicketsTable() {
  const [searchParams] = useSearchParams();
  const seriesId = searchParams.get("id");

  const {
    setSelectedTickets,
    selectedTickets,
    tickets,
  } = useBuildRepacks();

  const handleRowSelectionChange = (ids: GridRowId[]) => {
    const selectedRows = ids.map(id => tickets.find(ticket => ticket.id === id)); 
    setSelectedTickets(selectedRows as RepackTicket[]); 
  };  

  const filteredTickets = tickets.filter((ticket: RepackTicket) => ticket.repackSeries?.publicId === seriesId);

  return (
    <CollapsiblePaper
      title="Add Tickets"
      openTitle="View Available Tickets"
      closeTitle="Hide Available Tickets"
    >
      <Box sx={{ maxHeight: "50%", height: "100%", width: "100%" }}>
        <DataGridPro
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slots={{
            toolbar: CartViewToolbar,
          }}
          slotProps={{
            toolbar: { displayType: "tickets" },
            row: { draggable: "true" },
          }}
          density="compact"
          rows={filteredTickets}
          columns={[
            { field: "id", headerName: "Id", editable: false },
            {
              field: "repackTicketTypeId",
              headerName: "Ticket Type",
              editable: false,
              valueGetter: (params: any) => params.row.repackTicketType.name,
            },
            { field: "cost", headerName: "Cost", editable: false },
          ]}
          pagination={false}
          disableColumnReorder
          checkboxSelection
          onRowSelectionModelChange={handleRowSelectionChange}
          rowSelectionModel={selectedTickets.map((ticket) => ticket.id)}
        />
      </Box>
    </CollapsiblePaper>
  );
}