import React, { useCallback, useEffect } from "react";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import SeriesInfoPanel from "./SeriesInfoPanel";
import TicketsTable from "./TicketView";
import RepackStorePreview from "./RepackStorePreview";
import { Box, Grid, Typography, useMediaQuery, useTheme, Paper } from "@mui/material";
import StagedRepackPreview from "./StagedRepackPreview";
import { RepackBoxType } from "../Types/RepackBoxType";

export default function RepackCreationContent() {
  const {
    boxTypes,
    selectedType,
    setSelectedType,
    removeFromRepackStoreRepackVersion,
    setId,
  } = useBuildRepacks();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromUrl = urlParams.get("id");
    setId(idFromUrl);
  }, [setId]);

  const TicketView = useCallback(() => {
    return <TicketsTable />;
  }, [removeFromRepackStoreRepackVersion]);

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box 
      sx={{ 
        padding: 3,
        backgroundColor: theme.palette.background.default, 
        height: '100%',
        overflow: "auto"
      }}
    > 
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 3,
          fontWeight: 'bold',
          paddingLeft: "2%",
          paddingTop: "0.4%"
        }}
      >
        Repack Creation
      </Typography>

      <Box 
        className="repack-container" 
        sx={{ 
          padding: 2,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          height: 'calc(100% - 80px)',
          overflow: 'auto'
        }}
      > 
        <Grid 
          container 
          spacing={3} 
          direction={isXSmallScreen ? 'column' : 'row'}
        >
          {/* Left side - Card Selection */}
          <Grid item xs={12} md={6}>
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}
            >
              <RepackStorePreview />
              <TicketView />
            </Box>
          </Grid>

          {/* Right side - Repack Configuration */}
          <Grid item xs={12} md={6}>
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}
            >
              {/* Staged Repacks */}
              <Paper 
                elevation={2}
                sx={{ p: 2 }}
              >
                <StagedRepackPreview />
              </Paper>

              {/* Series Info */}
              <Paper 
                elevation={2}
                sx={{ p: 2 }}
              >
                <SeriesInfoPanel
                  boxTypes={boxTypes as RepackBoxType[]}
                  setSelectedType={setSelectedType}
                  selectedType={selectedType}
                  boxes={[]}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}