import { 
    CircularProgress, 
    Collapse, 
    IconButton, 
    Paper, 
    Typography 
} from "@mui/material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type CollapsiblePaperProps = {
    children?: React.ReactNode;
    loading?: boolean;
    title: string;
    closeTitle: string;
    openTitle: string;
}

const CollapsiblePaper = ({ 
    children, 
    loading,
    title,
    closeTitle,
    openTitle
 }: CollapsiblePaperProps ) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    if (loading === undefined) {
        loading = false;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ margin: 16 }}>
          <Paper style={{ padding: 8, position: "relative" }}>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",   
                }}
            >
            <Typography variant="h5">{title}</Typography>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" style={{ marginRight: 8 }}>
                  {expanded ? closeTitle : openTitle}
                </Typography>
                <IconButton onClick={() => setExpanded(!expanded)}>
                  {expanded ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
              </div>
            </div>
            </div>
            <Collapse in={expanded}>
              {children}
            </Collapse>
          </Paper>
        </div>
        </div>
    )
}
export default CollapsiblePaper;