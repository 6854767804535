import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import FetchModule from "../../../Modules/FetchModule";
import { SelectChangeEvent } from "@mui/material";
import useFetch from "../../../Components/CommonUtility/useFetch";
import { useNavigate } from "react-router-dom";
import RepackTabs from "../ViewRepacks/RepackTabs";
import mockData from "../ViewRepacks/MockData";
import RepackTicketView from "../RepackTicketManager/RepackTicketView";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";

const RepackLanding: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { newSnackbarMessage } = useSnackbar();
  const [stagedRepackSeries, setStagedRepackSeries] = useState<string>("");
  const [isNewSeries, setIsNewSeries] = useState<boolean>(false);
  const [newSeriesProductId, setNewSeriesProductId] = useState<string>("");
  const fetchModule = new FetchModule();
  const { data } = useFetch("/repack/get/allstagedseries/", true, []);
  const handleSeriesChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setStagedRepackSeries(value);
    setIsNewSeries(value === "new");
  };
  const handleButtonClick = async () => {
    if (isNewSeries) {
      try {
        const response = await fetchModule
          .postResponseBody("/repack/new/series", "POST", {
            UserId: user["id"],
            Body: {
              productId: newSeriesProductId,
              boxes: [],
              modifiedBy: user,
              isStaged: true,
            },
          })
          .then((res) => res.json());

        if (response && response.result) {
          const result = response.result;
          newSnackbarMessage("Repack series created successfully.", "success");
          navigate(`/dashboard/repack/create?id=${result.publicId}`);
        } else {
          throw new Error("Failed to create repack series");
        }
      } catch (error) {
        console.error("Error while creating repack series:", error);
        newSnackbarMessage("Error creating repack series", "error");
      }
    } else {
      const repackSeries = data.find(
        (series: any) => series.id === stagedRepackSeries
      );
      navigate(`/dashboard/repack/create?id=${repackSeries.publicId}`);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <CollapsiblePaper
        title="Repack"
        openTitle="Create Repack Series"
        closeTitle="Hide Create Repack Series"
      >
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-repack-series-label">
                Select Repack Series
              </InputLabel>
              <Select
                labelId="select-repack-series-label"
                id="select-repack-series"
                label="Select Repack Series"
                value={stagedRepackSeries}
                onChange={handleSeriesChange}
              >
                {data.map((series: any) => (
                  <MenuItem key={series.id} value={series.id}>
                    {series.productId}
                  </MenuItem>
                ))}
                <MenuItem value="new">New</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {isNewSeries && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="New Series Product ID"
                value={newSeriesProductId}
                onChange={(e) => setNewSeriesProductId(e.target.value)}
                style={{ marginTop: 0 }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{ textAlign: "right", marginTop: isNewSeries ? 16 : 0 }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !stagedRepackSeries || (isNewSeries && !newSeriesProductId)
              }
              onClick={handleButtonClick}
            >
              {isNewSeries
                ? "Create Repack Series"
                : "Modify Staged Repack Series"}
            </Button>
          </Grid>
        </Grid>
      </CollapsiblePaper>

      <div
        style={{
          display: "flex",
          flex: 1,
          margin: 16,
          gap: 16,
          overflowY: "scroll",
        }}
      >
        <Paper style={{ flex: 1, padding: 10, overflowY: "scroll" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h5">Repack Series</Typography>
            <Box>
              <Button
                variant="contained"
                onClick={() => navigate("/dashboard/repack/view")}
                style={{ marginRight: 8 }}
              >
                Manage All Repacks
              </Button>
            </Box>
          </Box>
          <RepackTabs repacks={mockData} />
        </Paper>

        <Paper style={{ flex: 1, padding: 10, overflowY: "scroll" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h5">Repack Tickets</Typography>
            <Box>
              <Button
                variant="contained"
                onClick={() => navigate("/dashboard/repack/tickets")}
                style={{ marginRight: 8 }}
              >
                Create and Manage Tickets
              </Button>
            </Box>
          </Box>
          <RepackTicketView />
        </Paper>
      </div>
    </div>
  );
};

export default RepackLanding;
