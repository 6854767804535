import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useGridApiContext } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface CustomDateInputProps {
  id: string | number;
  field: string;
  value: string;
  setSnackbar: (params: { children: string; severity: 'error' | 'success' | 'info' | 'warning' }) => void;
}

interface DateValidationResult {
  isValid: boolean;
  error?: string;
  dateParts?: string[];
}

const CustomDateInput: React.FC<CustomDateInputProps> = ({ id, field, value, setSnackbar }) => {
  const apiRef = useGridApiContext();

  const formatInitialDate = (dateString: string): string => {
    const date = dayjs.utc(dateString);
    return date.isValid() ? date.format("MM/DD/YYYY") : "";
  };

  const [stringValue, setStringValue] = useState(formatInitialDate(value));

  const validateDateParts = (date: string): DateValidationResult => {
    const cleanDate = date.replace(/-/g, '/');
    
    if (/[^0-9/]/.test(cleanDate)) {
      return { isValid: false, error: "BadData" };
    }

    const dateParts = cleanDate.split('/');
    
    if (dateParts.length < 2 || dateParts.length > 3) {
      return { isValid: false, error: "InvalidFormat" };
    }

    const currentYear = dayjs().year().toString();
    const processedParts = [...dateParts];

    // Add current year if only month and day provided
    if (dateParts.length === 2) {
      processedParts.push(currentYear);
    }

    // Pad month and day with leading zeros
    processedParts[0] = processedParts[0].padStart(2, '0');
    processedParts[1] = processedParts[1].padStart(2, '0');

    // Validate year format
    if (!processedParts[2].startsWith("202") && processedParts[2].length !== 4) {
      processedParts[2] = currentYear;
    }

    const dateObj = dayjs.utc(`${processedParts[2]}-${processedParts[0]}-${processedParts[1]}`);
    
    // Check for future dates (more than 7 days ahead)
    if (dateObj.isAfter(dayjs().add(7, 'day'))) {
      return { isValid: false, error: "Invalid Future Date" };
    }

    return { isValid: true, dateParts: processedParts };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStringValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      (event.target as HTMLElement).blur();
    }
  };

  const submitChange = () => {
    if (!stringValue) {
      apiRef.current.setEditCellValue({ id, field, value: "" });
      return;
    }

    const validation = validateDateParts(stringValue);
    
    if (!validation.isValid) {
      setSnackbar({
        children: `Error processing data: ${validation.error}`,
        severity: "error",
      });
      return;
    }

    const date = dayjs.utc(`${validation.dateParts![2]}-${validation.dateParts![0]}-${validation.dateParts![1]}`);
    
    if (!date.isValid()) {
      apiRef.current.setEditCellValue({ id, field, value: "Bad Data" });
      setSnackbar({
        children: `Error processing data: Invalid date format`,
        severity: "error",
      });
      return;
    }
    setStringValue(date.format('MM/DD/YYYY'));
    apiRef.current.setEditCellValue({ id, field, value: date.toISOString() });
  };

  return (
    <TextField
      inputProps={{
        style: { fontSize: 14 },
        'aria-label': 'Date input'
      }}
      size="small"
      fullWidth
      hiddenLabel
      onKeyDown={handleKeyDown}
      value={stringValue}
      onChange={handleChange}
      onBlur={submitChange}
    />
  );
};

export default CustomDateInput;