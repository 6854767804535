import React, { useEffect, useState } from "react";
import InfoGrid from "./InfoGrid";
import RepackTabs from "./RepackTabs";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import mockData from "./MockData";

interface RepackTypeCounts {
  [key: string]: number;
}

const ViewRepacks: React.FC = () => {
  const [, setHeaderVars] = useState({
    totalRepacks: 0,
    typeCounts: {} as RepackTypeCounts,
  });

  useEffect(() => {
    const counts = mockData.reduce(
      (acc, repack) => {
        acc.totalRepacks++;
        const { type } = repack;

        if (!acc.typeCounts[type]) {
          acc.typeCounts[type] = 1;
        } else {
          acc.typeCounts[type]++;
        }

        return acc;
      },
      { totalRepacks: 0, typeCounts: {} as RepackTypeCounts }
    );

    setHeaderVars(counts);
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">View Repacks</Typography>
        {/* TODO: change this to be a modal to select staged repacks or create a new one */}
        <Button
          variant="contained"
          component={Link}
          to="/dashboard/repack/create"
        >
          Create a Repack
        </Button>
      </div>
      <InfoGrid data={mockData} />
      <RepackTabs repacks={mockData} />
    </div>
  );
};

export default ViewRepacks;
