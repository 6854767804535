import { DataGridPro , useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";
import DashboardDefault from "../CardOrderDefaults/DashboardDefault";
import CustomDateInput from "../CardsHomeComponents/CustomDateInput";
import { Alert, Snackbar, Typography } from "@mui/material";
import AutocompleteSkuItem from "../AutocompleteComponents/AutocompleteSkuItem";
import FetchModule from "../../../Modules/FetchModule";
import AutocompleteAttributes from "../AutocompleteComponents/AttributesAutocomplete";
import CustomTextInput from "../CardsHomeComponents/CustomTextInput";
import AutocompleteUser from "../AutocompleteComponents/AutocompleteUser";
import StatusSelect from "../FilterComponents/CustomStatusFilter";
import BulkCardsToolbar from "./BulkCardsToolbar";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import BulkCardsSubmitModal from "./BulkCardsSubmitModal";
import propogateChanges from "./PropogateChanges";
import getInitialState from "./initialState";
import SimpleAutocomplete from "../AutocompleteComponents/SimpleAutocomplete";
import CustomNumberInput from "../CardsHomeComponents/BulkCustomNumberInput";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";
import { useCardDashboard } from "../CardDashboardContext";
import { useAddSkuModal } from "../AutocompleteAddModals/AddSkuModalContext";
import { useAddPlayerModal } from "../AutocompleteAddModals/AddPlayerModalContext";
import DataGridAutocomplete from "../../../Components/Autocomplete/DatagridAutocomplete";

const defaultCard = {
  attributes: "",
  cardNumber: "",
  certNumber: null,
  comp: 0,
  cost: 0,
  description: "",
  fees: 0,
  grade: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  gradeRec: "",
  gradeSent: "",
  gradingFees: 0,
  id: 1,
  initials: "",
  inventoryId: "",
  isDear: false,
  lastModifiedBy: "",
  league: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  manufacturer: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  name: "",
  pantheonId: "",
  player: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  productId: "",
  publicId: "",
  purchase: "",
  purchaseOrder: "",
  purchasedBy: "",
  received: "",
  serial: "",
  series: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  setVariety: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  sku: "",
  status: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
  teams:[],
  totalCost: 0,
  vendor: "",
  year: {
    label: "",
    value: "",
    publicId: "00000000-0000-0000-0000-000000000000",
    cardSkuItemPublicId: "00000000-0000-0000-0000-000000000000",
  },
};

const BulkCardsCreator: React.FC = () => {
  const fetchData = new FetchModule();

  const [listType, setListType] = useState("graded");

  const useFetchGetSetX = (url: string, type: string) => {
    const [data, setData] = useState<any>(null);
    useEffect(() => {
      fetchData
        .fetchResponseAuthed(url, type)
        .then((res: any) => res.json())
        .then((data: any) => {
          const d = data.data ? JSON.parse(data.data) : [{...defaultCard}];
          const t = data.listType;
          setListType(t);
          setData(d);
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
        });
    }, [type, url]);
    return [data, setData];
  };


  const {vendors,setVendors,players,users,statusTypes,skuList,updateSkus,recalculateFilter,getChecklistData,findTeams,teams,modalOpen} = useCardDashboard();
  const {launchAddPlayerModal} = useAddPlayerModal();
  const {launchAddSkuModal} = useAddSkuModal();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const labelQuery = queryParams.get("label");
  const label = labelQuery ? decodeURIComponent(labelQuery) : "";
  const guidQuery = queryParams.get("publicId");
  const guid = guidQuery;



  const [bulkCardData, setBulkCardData] = useFetchGetSetX(
    `/Card/cardlist/${guid}`,
    "GET"
  );
  
 
  const [rows, setRows] = useState([{}]);
  const [columnWidths,setColumnWidths] = useLocalStorage(
    LocalStorageKey.CARD_DASHBOARD_WIDTH,
    DashboardDefault().width
  );
  const [snackbar, setSnackbar] = React.useState({});
  const handleCloseSnackbar = () => setSnackbar({});
  const [setModalOpen] = React.useState(false);
  const [pinnedRows, setPinnedRows] = useState<any>({ top: [] });
  const getTitle = (type: any) => {
    switch (type) {
      case "graded":
        return "Graded";
      case "raw":
        return "Raw";
      case "pending":
        return "Pending Reception";
      case "retail":
        return "Retail";
      default:
        return "";
    }
  };
  const typeTitle = getTitle(listType);

  const [rowState, setRowState] = useState<any>({});
  const [, setRowChanged] = React.useState(false);
  const [updatedValues, setUpdatedValues] = React.useState({});
  const [rowModesModel, setRowModesModel] = useState<any>({});
  const [isScrolling, setIsScrolling] = React.useState(false);
  const { user } = useUser();

  React.useEffect(() => {
    const valuesChanged =
      JSON.stringify(rowState) !== JSON.stringify(updatedValues);
    setRowChanged(valuesChanged);
  }, [rowState, updatedValues]);

  const handleRowModesModelChange = (newRowModesModel: any) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStart = (params: any) => {
    setRowState(params.row);
  };

  const handleRowEditStop = (params: any, event: any, isScrolling: boolean) => {
    if (isScrolling || modalOpen) {
      event.defaultMuiPrevented = true;
      return;
    }
    setUpdatedValues(params.row);
  };

  useEffect(() => {
    if (bulkCardData && bulkCardData.length > 0) {
      setRows(bulkCardData);
    }
  }, [bulkCardData, rows]);

  useEffect(() => {
    if (rows.length > 0) {
      const firstRow = rows[0];
      setPinnedRows({ top: [firstRow] });
    }
  }, [rows]);
 

  // NEW ATTEMPT AT PROCESS ROW UPDATE
  const processRowUpdate = async (newRow: any, oldRow: any) => {
    
    let updatedRows;

    if (newRow.id === 1) {
      updatedRows = propogateChanges(newRow, oldRow, rows, true);
      setRows(updatedRows);
      setBulkCardData({ listType: listType, cards: updatedRows });

      const updatedRowsJson = JSON.stringify(updatedRows);

      const updateBody = {
        body: {
          UserId: user["id"],
          label: label,
          data: updatedRowsJson,
        },
        guid,
      };

      await fetchData.promise(
        "/Card/update/cardlist",
        true,
        "POST",
        updateBody
      );
    } else {
      updatedRows = rows.map((row: any) =>
        row.id === newRow.id ? newRow : row
      );
      setRows(updatedRows);
      setBulkCardData({ listType: listType, cards: updatedRows });

      const updatedRowsJson = JSON.stringify(updatedRows);

      const updateBody = {
        body: {
          UserId: user["id"],
          label: label,
          data: updatedRowsJson,
        },
        guid,
      };

      await fetchData.promise(
        "/Card/update/cardlist",
        true,
        "POST",
        updateBody
      );
    }

    return newRow;
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({
      children: "Error: " + error,
      severity: "error",
    });
    console.error("Error updating row: ", error);
  }, []);

  // Handle Scrolling here
  const gridRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 250);
    };

    const observer = new MutationObserver(() => {
      const gridElement = gridRef.current?.querySelector(
        ".MuiDataGrid-virtualScroller"
      ) as HTMLElement | null;
      if (gridElement) {
        gridElement.addEventListener("scroll", handleScroll);
        observer.disconnect(); // Stop observing once the element is found and event is bound
      }
    });

    const currentGridRef = gridRef.current;
    if (currentGridRef) {
      observer.observe(currentGridRef, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      const gridElement = gridRef.current?.querySelector(
        ".MuiDataGrid-virtualScroller"
      ) as HTMLElement | null;
      if (gridElement) {
        gridElement.removeEventListener("scroll", handleScroll);
      }
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (statusTypes && skuList) {
      const status =
      listType === "pending"
          ? statusTypes.find(
              (status: any) => status.label === "PendingConfirmation"
            )
          : statusTypes.find((status: any) => status.label === "InInventory");
      defaultCard.status = status;
    }
    const savedRows = rows.length ? rows : [{ ...defaultCard }];
    setRows(savedRows);
    setBulkCardData({ listType: listType, cards: savedRows });
    setPinnedRows({ top: [savedRows[0]] });
  }, [rows]);

  const renderCustomDate = React.useCallback((params) => {
    return <CustomDateInput {...params} setSnackbar={setSnackbar} />;
  }, []);

  const renderACDisplayCell = (params: any) => {
    const label = params.value ? params.value.label : "";
    const width = columnWidths[params.field];

    return (
      <Typography style={{ width: width }} className="fourteen">
        {label}
      </Typography>
    );
  };

  const renderAutocompleteCellSkuItem = React.useCallback(
    (params) => {
      return (
        <AutocompleteSkuItem
          {...params}
          width={columnWidths[params.field]}
          addNewSKU={updateSkus}
          setModalOpen={setModalOpen}
        />
      );
    },
    [columnWidths, setModalOpen]
  );
  const renderAutocompleteCellSkuItemPropogateChecklistSearch = React.useCallback(
    (params) => {

      return (
        <DataGridAutocomplete
          {...params}
          value={params.value}
          width={columnWidths[params.field]}
          data={skuList[params.colDef.skuType].items}
          handleAddToData={launchAddSkuModal}
          typePublicId={skuList[params.colDef.skuType].publicId}
          field={params.colDef.field}
          labelPath="label"
          propogateOnBlur = {(row:any) => {
            recalculateFilter(row)
          }}
        />
      );
    },
    [columnWidths, setModalOpen]
  );

  const renderAutocompleteCellSetVariety = React.useCallback(
    (params,apiRef) => {

      const data = skuList["Set"].items.map((item:any) =>{ return{...item,origin:"Database"}})
      const checklistDataTemp = getChecklistData(params.row.id)
      const checklistData = checklistDataTemp.map((item:any) =>{ return{...item,origin:"Checklist"}})
      const combinedData = data.concat(checklistData)
      return (
        <DataGridAutocomplete
          {...params}
          value={params.value}
          width={columnWidths[params.field]}
          data={combinedData}
          handleAddToData={launchAddSkuModal}
          typePublicId={skuList[params.colDef.skuType].publicId}
          field={params.colDef.field}
          labelPath="label"
          groupPath="origin"
          isGrouped
          propogateOnBlur={(row:any) => {
            const player = row.player?.label;
            const year = row.year?.label;
            const manufacturer = row.manufacturer?.label;
            const set = row.setVariety?.label;
            const series = row.series?.label;

            if (player && year && manufacturer && set && series) {
                findTeams(player, year, manufacturer, series, set, apiRef, params.row.id, "teams");
            }
            
          }}
        />
      );
    },[columnWidths,setModalOpen,recalculateFilter]
  )
  const renderMultiSelectAutoCompleteCell = React.useCallback(
    (params) => {
      return (
        <DataGridAutocomplete
          data={teams}
          isMultiselect
          id={params.id}
          field={params.field}
          value={params.row[params.field]}
          labelPath="name"
          {...params}
        />
      );
    },
    [teams]
  );
  const renderMultiselectACDisplayCell = (params:any) => {
    const label = params.value ?  params.value.map((obj: { name: any; }) => obj.name).join('/') : "";
    const width = columnWidths[params.field]

    return <Typography style={{ fontSize: "14px", width: width }}>{label}</Typography>;
  };



  const renderSimpleAutocomplete = React.useCallback(
    (params) => {
      return (
        <SimpleAutocomplete
          {...params}
          width={columnWidths[params.field]}
          options={vendors}
          setOptions={setVendors}
        />
      );
    },
    [columnWidths, vendors]
  );

  const renderCustomNumberInput = React.useCallback((params) => {
    return <CustomNumberInput {...params} />;
  }, []);

  const renderAutoCompleteCellPlayer = useCallback((params) => {
    return (
      <DataGridAutocomplete
        {...params}
        value={params.value}
        width={columnWidths[params.field]}
        data={players}
        handleAddToData={launchAddPlayerModal}
        labelPath="label"
        propogateOnBlur = {(row:any) => {
          recalculateFilter(row)
        }}
      />
    );
  }, [columnWidths, players, launchAddPlayerModal]);
  
  const renderAttributesSelectCell = React.useCallback((params) => {
    const options = [
      { value: "RC", label: "RC" },
      { value: "Auto", label: "Auto" },
      { value: "Auto RC", label: "Auto RC" },
      { value: "1st", label: "1st" },
      { value: "1st Auto", label: "1st Auto" },
      { value: "", label: "Empty" },
    ];

    return (
      <AutocompleteAttributes
        className="fourteen"
        {...params}
        options={options}
      />
    );
  }, []);

  const renderAttributesSelectDisplayCell = (params: any) => {
    const value = params.value ? params.value : "";
    return <Typography className="fourteen">{value ? value : ""}</Typography>;
  };

  const renderCustomTextInput = React.useCallback((params) => {
    return <CustomTextInput {...params} />;
  }, []);

  const renderCert = (params: any) => {
    const label = params.value ? params.value : "";
    const isPSA =
      params.row.grade &&
      params.row.grade.label &&
      params.row.grade.label.includes("PSA");
    const certClick = () => {
      window.open(
        "https://www.psacard.com/cert/" + label,
        "_blank",
        "noopener,noreferrer"
      );
    };

    return (
      <Typography onClick={isPSA ? certClick : () => {}} className="fourteen">
        {label}
      </Typography>
    );
  };

  const renderCustomUser = React.useCallback(
    (params) => {
      return (
        <AutocompleteUser
          datafields={users || []}
          {...params}
          setSnackbar={setSnackbar}
        />
      );
    },
    [users]
  );

  const renderUser = (params: any) => {
    const label = params.value ? params.value : "";
    return <Typography className="fourteen">{label}</Typography>;
  };
  //PendingConfirmation
  const renderSelectCell = React.useCallback(
    (params) => {
      return (
        <StatusSelect
          width={columnWidths[params.field]}
          className="fourteen"
          {...params}
          statusTypes={statusTypes}
          onKeyDown={(event: any) => {
            if (event.key === "Tab") {
              event.target.blur();
            }
          }}
        />
      );
    },
    [statusTypes]
  );

  const renderSelectDisplayCell = (params: any) => {
    const label = params.value?.label ? params.value?.label : "";
    return <Typography className="fourteen">{label ? label : ""}</Typography>;
  };

  const apiRef = useGridApiRef();
  const cols = [
    {
      field: "id",
      headerName: "Temp ID",
      type: "number",
      width: columnWidths["Grade"],
      editable: false,
      valueGetter: (params: any) => {
        if (params.row.id === 1) {
          return "Template";
        }
        return params.row.id - 1;
      },
    },
    {
      field: "purchase",
      headerName: "Purchased",
      type: "date",
      width: columnWidths["purchase"],
      editable: true,
      renderEditCell: renderCustomDate,
      valueGetter: (params: any) =>
        params.value ? new Date(params.value) : null,
    },
    {
      field: "received",
      headerName: "Received",
      type: "date",
      width: columnWidths["received"],
      editable: true,
      renderEditCell: renderCustomDate,
      valueGetter: (params: any) =>
        params.value ? new Date(params.value) : null,
    },
    {
      field: "player",
      headerName: "Player",
      width: columnWidths["player"],
      editable: true,
      renderEditCell: renderAutoCompleteCellPlayer,
      renderCell: renderACDisplayCell,
      type: "text",
    },
    {
      field: "year",
      headerName: "Year",
      width: columnWidths["year"],
      editable: true,
      skuType: "Year",
      renderEditCell: renderAutocompleteCellSkuItemPropogateChecklistSearch,
      renderCell: renderACDisplayCell,
      type: "text",
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: columnWidths["manufacturer"],
      editable: true,
      skuType: "Manufacturer",
      renderEditCell: renderAutocompleteCellSkuItemPropogateChecklistSearch,
      renderCell: renderACDisplayCell,
      type: "text",
    },
    {
      field: "series",
      headerName: "Series",
      width: columnWidths["series"],
      type: "text",
      skuType: "Series",
      editable: true,
      renderEditCell: renderAutocompleteCellSkuItemPropogateChecklistSearch,
      renderCell: renderACDisplayCell,
    },
    {
      field: "setVariety",
      headerName: "Set/Variety",
      editable: true,
      skuType: "Set",
      width: columnWidths["setVariety"],
      type: "text",
      renderEditCell:(params:any) =>{
        return renderAutocompleteCellSetVariety(params,apiRef)
      },
      renderCell: renderACDisplayCell,
    },
    {
      field: "attributes",
      headerName: "Attributes",
      type: "text",
      width: columnWidths["attributes"],
      editable: true,
      renderEditCell: renderAttributesSelectCell,
      renderCell: renderAttributesSelectDisplayCell,
    },
    {
      field: "serial",
      headerName: "Serial",
      type: "text",
      width: columnWidths["serial"],
      editable: true,
      renderEditCell: renderCustomTextInput,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: columnWidths["grade"],
      skuType: "Grade",
      editable: true,
      type: "text",
      renderEditCell: renderAutocompleteCellSkuItem,
      renderCell: renderACDisplayCell,
    },
    {
      field: "gradeSent",
      headerName: "Grading Sent",
      type: "date",
      width: columnWidths["gradeSent"],
      editable: true,
      renderEditCell: renderCustomDate,
      valueGetter: (params: any) =>
        params.value ? new Date(params.value) : null,
    },
    {
      field: "gradeRec",
      headerName: "Grading Rec",
      type: "date",
      width: columnWidths["gradeRec"],
      editable: true,
      renderEditCell: renderCustomDate,
      valueGetter: (params: any) =>
        params.value ? new Date(params.value) : null,
    },
    {
      field: "certNumber",
      headerName: "Cert #",
      type: "string",
      width: columnWidths["certNumber"],
      editable: true,
      renderEditCell: renderCustomTextInput,
      renderCell: renderCert,
    },
    {
      field: "cardNumber",
      headerName: "Card #",
      type: "text",
      width: columnWidths["cardNumber"],
      editable: true,
      renderEditCell: renderCustomTextInput,
    },
    {
      field: "league",
      headerName: "League",
      width: columnWidths["league"],
      editable: true,
      skuType: "League",
      type: "text",
      renderEditCell: renderAutocompleteCellSkuItem,
      renderCell: renderACDisplayCell,
    },
    {
      field: "cost",
      headerName: "Cost",
      width: columnWidths["cost"],
      editable: true,
      type: "number",
      renderEditCell: renderCustomNumberInput,
    },
    {
      field: "fees",
      headerName: "Fees",
      width: columnWidths["fees"],
      editable: true,
      type: "number",
      renderEditCell: renderCustomNumberInput,
    },
    {
      field: "gradingFees",
      headerName: "Grading Fees",
      width: columnWidths["gradingFees"],
      editable: true,
      type: "number",
      renderEditCell: renderCustomNumberInput,
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: columnWidths["totalCost"],
      editable: false,
      valueGetter: (params: any) =>
        params.row.cost + params.row.gradingFees + params.row.fees,
      type: "number",
    },
    {
      field: "comp",
      headerName: "Comp",
      width: columnWidths["comp"],
      editable: true,
      type: "number",
      renderEditCell: renderCustomNumberInput,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: columnWidths["vendor"],
      editable: true,
      type: "text",
      renderEditCell: renderSimpleAutocomplete,
    },
    {
      field: "description",
      headerName: "Description",
      width: columnWidths["description"],
      editable: true,
      type: "text",
      renderEditCell: renderCustomTextInput,
    },
    {
      field: "purchasedBy",
      headerName: "Purchase By",
      width: columnWidths["purchsedBy"],
      editable: true,
      type: "text",
      renderEditCell: renderCustomUser,
      renderCell: renderUser,
    },
    {
      field: "status",
      headerName: "Status",
      width: columnWidths["status"],
      editable: true,
      type: "string",
      renderEditCell: renderSelectCell,
      renderCell: renderSelectDisplayCell,
    },
    {
      field: "teams",
      headerName: "Team",
      width: columnWidths["teams"],
      editable: true,
      renderEditCell: (params:any) => {
        return renderMultiSelectAutoCompleteCell(params);
      },
      renderCell: renderMultiselectACDisplayCell,
      type: "text",
    },
  ];
  const  changeColumnWidth  = (event: any) => {
    const { field: columnId, width: newWidth } = event.colDef;
    setColumnWidths({
      ...columnWidths,
      [columnId]: newWidth,
    });
  }
  return (
    <>
      <div
        className="data-grid"
        style={{
          height: "90%",
          width: "100%",
          overflow: "scroll",
          fontSize: "0.75rem",
        }}
      >
        <Typography variant="h4" gutterBottom className="bulk-creator-header">
          {`${label}: ${typeTitle} Cards`}
        </Typography>

        <DataGridPro
          apiRef={apiRef}
          sx={{ overflow: "scroll" }}
          density="compact"
          rows={rows || []}
          getRowId={(row) => row.id ?? 0}
          columns={cols}
          editMode="row"
          pinnedRows={pinnedRows}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={(params, event) => {
            handleRowEditStop(params, event, isScrolling);
          }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onColumnWidthChange={changeColumnWidth}
          slots={{
            toolbar: BulkCardsToolbar,
          }}
          slotProps={{
            toolbar: {
              type: listType,
              user: user,
              setSnackbar: setSnackbar,
              rows: rows,
              setRows: setRows,
              cols: cols,
            },
          }}
          initialState={getInitialState(listType)}
        />
        {Object.keys(snackbar).length !== 0 && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={10000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>

      <BulkCardsSubmitModal
        rows={rows}
        cols={cols}
        pinnedRows={pinnedRows}
        type={listType}
        setRows={setRows}
        label={label}
        typeTitle={typeTitle}
        updatedRowsJson={JSON.stringify(rows)}
        guid={guid}
        skuList = {skuList}
        addNewSKU={updateSkus}

      />
    </>
  );
};

export default BulkCardsCreator;
