import React from "react";
import { Typography, Paper, List, ListItem, ListItemText } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { useRepackTicketContext } from "../../../Contexts/RepackContexts/RepackTicketContexts/RepackTicketContext";

const RepackTicketView: React.FC = () => {
  const { tickets, seriesList, ticketTypes } = useBuildRepacks();
  const { processRowUpdate } = useRepackTicketContext();

  const mockPrizes = [{ name: "Prize 1", cost: "$10" }];

  const getUsername = (params: any) => {
    if (params.row.modifiedBy) {
      return params.row.modifiedBy.username;
    } else if (params.row.modifiedById) {
      const u = params.row.modifiedById;
      return u.username;
    }
  };

  const getTypeName = (params: any) => {
    const ticketType = ticketTypes.find(
      (type: any) => type.id === params.row.repackTicketTypeId
    );
    return ticketType ? ticketType.name : "Unknown";
  };

  const getSeriesName = (params: any) => {
    const series = seriesList.find(
      (s: any) => s.id === params.row.repackSeriesId
    );
    return series ? series.productId : "Unknown";
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 50, editable: false },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: false,
      valueGetter: (params: any) => getTypeName(params),
    },
    {
      field: "cost",
      headerName: "Cost",
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "repackItemId",
      headerName: "Repack Item Id",
      width: 150,
      editable: false,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      width: 150,
      valueGetter: (params: any) => getUsername(params),
      editable: false,
    },
    {
      field: "repackSeries",
      headerName: "Repack Series",
      width: 150,
      valueGetter: (params: any) => getSeriesName(params),
      editable: false,
    },
  ];

  const sortModel: GridSortModel = [
    {
      field: "id",
      sort: "asc",
    },
  ];

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginTop: 16,
        overflowY: "scroll",
      }}
    >
      <Paper style={{ flex: 1 }}>
        <DataGridPro
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
          sx={{
            "& .MuiDataGrid-row.even-row": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDataGrid-row.odd-row": {
              backgroundColor: "#ffffff",
            },
          }}
          density="compact"
          rows={tickets}
          columns={columns}
          processRowUpdate={processRowUpdate}
          sortModel={sortModel}
          components={{
            Toolbar: GridToolbarContainer,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getDetailPanelContent={() => (
            <div style={{ padding: 16 }}>
              <Typography variant="h6">Prizes</Typography>
              <List>
                {mockPrizes.map((prize, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={prize.name}
                      secondary={`Cost: ${prize.cost}`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          getDetailPanelHeight={() => 200}
        />
      </Paper>
    </div>
  );
};

export default RepackTicketView;
